// @import url('https://fonts.googleapis.com/css?family=Anton|Noto+Serif:400,700');

@import url('https://fonts.googleapis.com/css?family=Raleway:400,700,900');

$header-font-family: 'Raleway', sans-serif;
$header-font-weight: 700;
$body-font-family: 'Raleway', sans-serif;
$background-color-service: #d2f994;
$section-color: rgba(#a6a6a6,.5);
$section-padding: 50px 0;
$full-width-color: #f90;
$border-top-color: #b41818;
$para-line-height: 1.8;
//35. card
$card-background: rgba(232,232,232,.89);
// $card-font-color: $global-font-color;
// $card-divider-background: #7a4b56
// $card-border: 1px solid $primary-color;
// $card-shadow: none;
// $card-border-radius: $global-radius;
// $card-padding: $global-padding;
// $card-margin: $global-margin;

p, li{
  line-height: $para-line-height !important;
}

hr {
  border: 0;
  border-top: 4px double #8c8c8c !important;
  text-align: center;
  max-width: 100% !important;
}
hr:after {
  font-family: FontAwesome;

  content: "\f2b5";
  display: inline-block;
  position: relative;
  top: -15px;
  padding: 0 10px;
  background: #fff;
  color: #8c8c8c;
  font-size: 18px;
}


.right{
  float: right;
}
.uppercase{
  text-transform: uppercase;
}

//topbar styling
.top-bar{
  position: fixed;
  top:0;
  width: 100%;
  border-bottom: 5px solid $border-top-color;
  z-index: 1000;
  height: 100px;
  transition: all .6s ease-in-out;
}

div#main-menu.top-bar.shrink{

    background-color: rgb(238,238,238);
    height: 80px;
    .logo{
      width: 340px;
    }
    .top-bar-right{
      margin-top: 10px;
    }
}

div#main-menu.top-bar{
  .logo{
    width: 450px;
    transition: all .6s ease-in-out;
  }
}


.top-bar-right{
  transition: all .6s ease-in-out;
  margin-top: 25px;
  font-weight: 700;
  a{
      color: $border-top-color;
      padding: 10px 5px;
      transition-property: all;
      transition-duration: 1s;
      &:hover{
        background-color: $primary-color;
        color: white;
        transition: all;
      }
    }
}

.submenu{
  background-color: lighten( $primary-color, 40% ) !important;
}

.dropdown.menu{
  .submenu{
    left: 0% !important;
  }
}




h1, h2, h3, h4, h5, h6{
  font-weight: $header-font-weight;
}

h1{
  line-height: 120% !important;
}
section{
  margin: 50px 0;
}

.full-width {
  background: $full-width-color;
  max-width: 100% !important;
  margin: 50px 0;
}
.container{
  @include grid-row;
}


.content {
  @include grid-column(8);
  border: 1px solid red;
}

.sidebar {
  @include grid-column(4);
}

// orbit styling

.orbit-container {
  position: relative;
  height: 80vh;
  border-bottom: 5px solid $border-top-color;
}
.orbit-caption {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
  background-color: rgba(10, 10, 10, 0.0) !important;
}
.orbit-caption p {
	position: relative;
	top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
	text-align: center;
	color: #fefefe;
  font-size: 48px;
  font-weight: 900;
  text-shadow: 2px 2px 2px #000000;
}
@media screen and (max-width: 39.9375em) {
  .orbit-container {
    height: 40vh;
  }
.orbit-caption p{
  font-size: 12px;
  font-weight: 400;
 }
}

.orbit-bullets {
  //  display: none;
}


.section, .service{
  text-align: center;
  // margin: 50px 0;
}


.service{
    padding: $section-padding;
    // background-color: rgba(3,107,173,.2);
    background-color: rgba(143,206,241,.8);
    // color: rgb(143,206,241)
    // background: linear-gradient(
    //   rgba(3,107,173,.5),
    //   rgba(3,107,173,.9));
      // background-size: cover;
      // background-position: center;
      // background-repeat: no-repeat;

    .service-sub-head{
      margin-bottom: 25px;
      font-weight: 900;
      text-transform: uppercase;
    }
      h1, h4{
        color: white;
        text-shadow: 2px 2px 1px #333;
      }
      h5{
        margin-bottom: 20px;
        font-weight: 300;
        font-size: 98%;
      }
}


// media query for medium and up
@media screen and (min-width: 40em) {
  .service{
    // padding: $section-padding;
    background: linear-gradient(
      rgba(3,107,173,.5),
      rgba(3,107,173,.9)), url("/assets/img/financial_report_medium.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
}
// Media Query for Large screen
@media screen and (min-width: 64em) {
  .service{
    background: linear-gradient(
      rgba(3,107,173,.5),
      rgba(3,107,173,.9)), url("/assets/img/financial_report_large.jpg");
      background-size: cover;
      background-repeat: no-repeat;
  }
}



.card{
  background: $card-background;
  opacity: .75;
  border-top: 5px double #f96e0a;
  border-bottom: 2px solid #f96e0a;
  margin-bottom: 30px;
  padding: 30px;
    h2{
      color: $border-top-color;
      text-shadow: 2px 2px 1px #fff;
      font-weight: 900;
      font-size: 2rem;
    }
    .icon{
      margin: 10px 0;
      color: white;
      .fa{
        background: #f96e0a;
        font-size: 2.5em !important;
        padding: .50em;
        margin-bottom: 20px;
        border-radius: 60px;
      }
      .fa-usd{
        padding: .50em .75em;
      }
    }
  .button{
    margin: 0 0 20px 0;
    font-weight: 700;
  }
}

.bg-image-position{
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  height: 50vh;
  margin-bottom: 20px;
}

/* why-cammtac */

.why-cammtac-image{
      @extend .bg-image-position;
      background: url("/assets/img/cammtac_why_small.jpg");
}

// media query for medium and up
@media screen and (min-width: 40em) {
    .why-cammtac-image{
      background: url("/assets/img/cammtac_why_medium.jpg");
    }
}

// Media Query for Large screen
@media screen and (min-width: 64em) {
  .why-cammtac-image{
    background: url("/assets/img/cammtac_why_large.jpg");
  }
}

/* our mission */

.mission-bg-image{
  @extend .bg-image-position;
  background: url("/assets/img/bar_chart_growth_small.jpg");
}

// media query for medium and up
@media screen and (min-width: 40em) {
    .mission-bg-image{
      background: url("/assets/img/bar_chart_growth_medium.jpg");
    }
}

// Media Query for Large screen
@media screen and (min-width: 64em) {
  .mission-bg-image{
    background: url("/assets/img/bar_chart_growth_large.jpg");
  }
}

// contact-us
.contact-bg-image{
  @extend .bg-image-position;
  background: url("/assets/img/cammtac_contact_us_small.jpg");
}

      // media query for medium and up
      @media screen and (min-width: 40em) {
          .contact-bg-image{
            background: url("/assets/img/cammtac_contact_us_medium.jpg");
          }
      }

      // Media Query for Large screen
      @media screen and (min-width: 64em) {
        .contact-bg-image{
          background: url("/assets/img/cammtac_contact_us_large.jpg");
        }
      }



.bookkeeping-bg-image{
  @extend .bg-image-position;
  background: url("/assets/img/cammtac_bookkeeping_small.jpg");
}

      // media query for medium and up
      @media screen and (min-width: 40em) {
          .bookkeeping-bg-image{
            background: url("/assets/img/cammtac_bookkeeping_medium.jpg");
          }
      }

      // Media Query for Large screen
      @media screen and (min-width: 64em) {
        .bookkeeping-bg-image{
          background: url("/assets/img/cammtac_bookkeeping_large.jpg");
        }
      }

.accounting-bg-image{
        @extend .bg-image-position;
        background: url("/assets/img/cammtac_macbook.jpg");
      }

            // media query for medium and up
            @media screen and (min-width: 40em) {
                .bookkeeping-bg-image{
                  background: url("/assets/img/cammtac_macbook.jpg");
                }
            }

            // Media Query for Large screen
            @media screen and (min-width: 64em) {
              .bookkeeping-bg-image{
                background: url("/assets/img/cammtac_bookkeeping_large.jpg");
              }
            }


.charted-accountant-support-bg{
  @extend .bg-image-position;
  background: url("/assets/img/cammtac_business_loan_small.jpg");
}

      // media query for medium and up
      @media screen and (min-width: 40em) {
          .bookkeeping-bg-image{
            background: url("/assets/img/cammtac_business_loan_medium.jpg");
          }
      }

      // Media Query for Large screen
      @media screen and (min-width: 64em) {
        .bookkeeping-bg-image{
          background: url("/assets/img/cammtac_business_loan_large.jpg");
        }
      }


.mortage-bg-image{
  @extend .bg-image-position;
  background: url("/assets/img/cammtac_happy_family_small.jpg");
}

        // media query for medium and up
        @media screen and (min-width: 40em) {
            .mortage-bg-image{
              background: url("/assets/img/cammtac_happy_family_medium.jpg");
            }
        }

        // Media Query for Large screen
        @media screen and (min-width: 64em) {
          .mortage-bg-image{
            background: url("/assets/img/cammtac_happy_family_large.jpg");
          }
        }

        .mortage-bg-image{
          @extend .bg-image-position;
          background: url("/assets/img/cammtac_happy_family_small.jpg");
        }

                // media query for medium and up
                @media screen and (min-width: 40em) {
                    .mortage-bg-image{
                      background: url("/assets/img/cammtac_happy_family_medium.jpg");
                    }
                }

                // Media Query for Large screen
                @media screen and (min-width: 64em) {
                  .mortage-bg-image{
                    background: url("/assets/img/cammtac_happy_family_large.jpg");
                  }
                }

.mortage-insurance-bg-image{
  @extend .bg-image-position;
  background: url("/assets/img/cammtac_mortgage_insurance_small.jpg");
  }

                        // media query for medium and up
                        @media screen and (min-width: 40em) {
                            .mortage-insurance-bg-image{
                              background: url("/assets/img/cammtac_mortgage_insurance_medium.jpg");
                            }
                        }

                        // Media Query for Large screen
                        @media screen and (min-width: 64em) {
                          .mortage-insurance-bg-image{
                            background: url("/assets/img/cammtac_mortgage_insurance_large.jpg");
                          }
                        }

.insurance-product-bg-image{
  @extend .bg-image-position;
  background: url("/assets/img/cammtac_insurance_small.jpg");
}

        // media query for medium and up
        @media screen and (min-width: 40em) {
            .insurance-product-bg-image{
              background: url("/assets/img/cammtac_insurance_medium.jpg");
            }
        }

        // Media Query for Large screen
        @media screen and (min-width: 64em) {
          .insurance-product-bg-image{
            background: url("/assets/img/cammtac_insurance_large.jpg");
          }
        }


        .insurance-bg-image{
          @extend .bg-image-position;
          background: url("/assets/img/cammtac_happy_family_small.jpg");
        }

                // media query for medium and up
                @media screen and (min-width: 40em) {
                    .insurance-bg-image{
                      background: url("/assets/img/cammtac_happy_family_medium.jpg");
                    }
                }

                // Media Query for Large screen
                @media screen and (min-width: 64em) {
                  .insurance-bg-image{
                    background: url("/assets/img/cammtac_happy_family_large.jpg");
                  }
                }



.taxprep-bg-image{
  @extend .bg-image-position;
  background: url("/assets/img/cammtact_tax_prep_small.jpg");
}

      // media query for medium and up
      @media screen and (min-width: 40em) {
          .taxprep-bg-image{
            background: url("/assets/img/cammtact_tax_prep_medium.jpg");
          }
      }

      // Media Query for Large screen
      @media screen and (min-width: 64em) {
        .taxprep-bg-image{
          background: url("/assets/img/cammtact_tax_prep_large.jpg");
        }
      }


.illness-bg-image{
  @extend .bg-image-position;
  background: url("/assets/img/cammtac_illness_insurance_small.jpg");
}

      // media query for medium and up
      @media screen and (min-width: 40em) {
          .illness-bg-image{
            background: url("/assets/img/cammtac_illness_insurance_medium.jpg");
          }
      }

      // Media Query for Large screen
      @media screen and (min-width: 64em) {
        .illness-bg-image{
          background: url("/assets/img/cammtac_illness_insurance_large.jpg");
        }
      }

.incometax-bg-image{
  @extend .bg-image-position;
  background: url("/assets/img/business_accocunting.jpg");
}

.succession-bg-image{
  @extend .bg-image-position;
  background: url("/assets/img/cammtac_suceesion_planning_small.jpg");
}

  // media query for medium and up
  @media screen and (min-width: 40em) {
      .succession-bg-image{
        background: url("/assets/img/cammtac_suceesion_planning_medium.jpg");
      }
  }

  // Media Query for Large screen
  @media screen and (min-width: 64em) {
    .succession-bg-image{
      background: url("/assets/img/cammtac_suceesion_planning_large.jpg");
    }
  }


.business-registration-bg-image{
  @extend .bg-image-position;
  background: url("/assets/img/cammtac_business_registration_small.jpg");
}
    // media query for medium and up
    @media screen and (min-width: 40em) {
        .business-registration-bg-image{
          background: url("/assets/img/cammtac_business_registration_medium.jpg");
        }
    }

    // Media Query for Large screen
    @media screen and (min-width: 64em) {
      .business-registration-bg-image{
        background: url("/assets/img/cammtac_business_registration_large.jpg");
      }
    }


.bookkeeping-bg-image{
  @extend .bg-image-position;
  background: url("/assets/img/cammtac_bookkeeping_small.jpg");
}

    // media query for medium and up
    @media screen and (min-width: 40em) {
        .bookkeeping-bg-image{
          background: url("/assets/img/cammtac_bookkeeping_medium.jpg");
        }
    }

    // Media Query for Large screen
    @media screen and (min-width: 64em) {
      .bookkeeping-bg-image{
        background: url("/assets/img/cammtac_bookkeeping_large.jpg");
      }
    }

.business-loan-bg-image{
  @extend .bg-image-position;
  background: url("/assets/img/cammtac_business_loan_small.jpg");
}
    // media query for medium and up
    @media screen and (min-width: 40em) {
        .business-loan-bg-image{
          background: url("/assets/img/cammtac_business_loan_medium.jpg");
        }
    }

    // Media Query for Large screen
    @media screen and (min-width: 64em) {
      .business-loan-bg-image{
        background: url("/assets/img/cammtac_business_loan_large.jpg");
      }
    }



.about-bg-image{
  @extend .bg-image-position;
  background: url("/assets/img/financial_report.jpg");
}

.split-section-photo-left{
    padding: 0;
    .columns{
      margin: 0 !important;
      padding: 0 !important;
    }

    ul{
      padding: 0;
      margin: 0;
    }

    ul li{
      list-style-type: none;
      margin-left: 0;
      padding-left: 30px;
      a{
        color: inherit;
        font-weight: 400;
        &:hover{
          color: $border-top-color;
          transition: all;
        }
      }
    }

    ul li:before{
        font-family: FontAwesome;
        content: "\f14b";
        margin-left: -1.5em;
        margin-right: .75em;
        color: red;
    }
}
.description-right{
  max-width: 90%;
  padding: 0 15% 0 5%;
  ul{
    margin-bottom: 15px;
  }

}
// button{
//   margin-top: 10px !important;
// }
.split-section-photo-right{
    .columns{
      margin: 0 !important;
      padding: 0 !important;
    }
    .description-left{
      max-width: 90%;
      padding: 0 5% 0 25%;
    }
    ul{
      padding: 0;
      margin: 0;
    }

    ul li{
      list-style-type: none;
      margin-left: 0;
      padding-left: 20px;
    }

    ul li:before{
        font-family: FontAwesome;
        content: "\f14b";
        margin-left: -1.5em;
        margin-right: .75em;
        color: red;
    }
}








.section-reverse {
    margin: 0;
    padding: 30px 0;
    color: white;
    text-transform: uppercase;
    text-align: center;
      h1{
        font-size: 100px;
      }
      h3{
        font-size: 40px;
      }
    }

//extended with here-intro
.hero-intro-section{
  padding: 0;
  position: relative;
  top: 40%;
  text-align: center;
  color: white;
  text-shadow: 2px 2px 1px #333;
}

.hero-section{
  margin: 0;
  padding: 10px 0;
  height: 70vh;
  border-bottom: 5px solid $border-top-color;

}
//Income Tax Prepartion
// Here Image section

.hero-incometax {
  background: url("/assets/img/cammtac_hero_incometax_small.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  @extend .hero-section;
      .hero-intro {
        @extend .hero-intro-section;
      }
  }

  // media query for medium and up
  @media screen and (min-width: 40em) {
      .hero-incometax {
        background: url("/assets/img/cammtac_hero_incometax_medium.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
      }
  }

  // Media Query for Large screen
  @media screen and (min-width: 64em) {
    .hero-incometax{
      background: url("/assets/img/cammtac_hero_incometax_large.jpg");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }
  }


  //Business Development
  // Here Image section

  .hero-business-development {
    background: url("/assets/img/cammtac_business_development_slider.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    @extend .hero-section;
        .hero-intro {
          @extend .hero-intro-section;
        }
    }

    // media query for medium and up
    @media screen and (min-width: 40em) {
        .hero-business-development{
          background: url("/assets/img/cammtac_business_development_slider.jpg");
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
        }
    }

    // Media Query for Large screen
    @media screen and (min-width: 64em) {
      .hero-business-development{
        background: url("/assets/img/cammtac_business_development_slider.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
      }
    }


  //Income Accounting and Bookkeeping
  // Here Image section

  .hero-accounting {
    background: url("/assets/img/cammtac_hero_accounting_small.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    @extend .hero-section;
        .hero-intro {
          @extend .hero-intro-section;
        }
    }

        // media query for medium and up
        @media screen and (min-width: 40em) {
            .hero-accounting{
              background: url("/assets/img/cammtac_hero_accounting_medium.jpg");
              background-repeat: no-repeat;
              background-size: cover;
              background-position: center;
            }
        }

        // Media Query for Large screen
        @media screen and (min-width: 64em) {
          .hero-accounting{
            background: url("/assets/img/cammtac_hero_accounting_large.jpg");
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
          }
        }

    //Income about cammtac
    // Here Image section

    .hero-about-cammtac {
      background: url("/assets/img/bar_chart_growth.jpg");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
        @extend .hero-section;
          .hero-intro {
            @extend .hero-intro-section;
          }
      }


      .hero-resources {
        background: url("/assets/img/bar_chart_growth.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
          @extend .hero-section;
            .hero-intro {
              @extend .hero-intro-section;
            }
        }
.hero-insurance {
  background: url("/assets/img/cammtac_insurance_hero_small.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
    @extend .hero-section;
      .hero-intro {
        @extend .hero-intro-section;
      }
}

    // media query for medium and up
    @media screen and (min-width: 40em) {
        .hero-accounting{
          background: url("/assets/img/cammtac_insurance_hero_medium.jpg");
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
        }
    }

    // Media Query for Large screen
    @media screen and (min-width: 64em) {
      .hero-accounting{
        background: url("/assets/img/cammtac_insurance_hero_large.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
      }
    }


.hero-investments {
  background: url("/assets/img/cammtac_investments_small.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  @extend .hero-section;
    .hero-intro {
    @extend .hero-intro-section;
    }
}

        // media query for medium and up
        @media screen and (min-width: 40em) {
            .hero-accounting{
              background: url("/assets/img/cammtac_investments_medium.jpg");
              background-repeat: no-repeat;
              background-size: cover;
              background-position: center;
            }
        }

        // Media Query for Large screen
        @media screen and (min-width: 64em) {
          .hero-accounting{
            background: url("/assets/img/cammtac_investments_large.jpg");
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
          }
        }


.hero-mortgage {
          background: url("/assets/img/cammtac_mortgage_small.jpg");
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
            @extend .hero-section;
              .hero-intro {
                @extend .hero-intro-section;
              }
          }

          // media query for medium and up
          @media screen and (min-width: 40em) {
              .hero-mortgage{
                background: url("/assets/img/cammtac_mortgage_medium.jpg");
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;
              }
          }

          // Media Query for Large screen
          @media screen and (min-width: 64em) {
            .hero-mortgage{
              background: url("/assets/img/cammtac_mortgage_large.jpg");
              background-repeat: no-repeat;
              background-size: cover;
              background-position: center;
            }
          }


  .intro-list{
      text-align: left;
      margin-left: 10%;
    }

    // form
    #wufoo-z1c9amll07973fj{
      margin-bottom: 20px;
      height: 500px;
    }

      //google maps

        .google-maps {
        position: relative;
        padding-bottom: 35%; // This is the aspect ratio
        height: 0;
        overflow: hidden;
          iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100% !important;
          height: 100% !important;
          }
        }



        // footer
        footer{
          a{
            color: inherit;
            text-decoration: none;
            font-weight: 700;
            transition: all 1s;
            &:visited{
              color: inherit;
            }
            &:hover, &:focus{
              color: white;
            }
          }
          background-color: $full-width-color;
          border-top: 5px solid $border-top-color;
          padding-top: 0px;

          li{
            line-height: 180%;
            color: #333;
          }
            .footer{
              margin-top: 10px;
              padding-top: 20px !important;
                .footer-columns{
                  padding-right: 60px;
                }
                h4{
                  border-bottom: 2px solid white;
                }
            }
            .copywrite{
              background-color: $border-top-color;
              color: white;
              font-size: 80%;
              margin: 0 auto;
              padding: 20px;
              .copywrite span{
                margin: 0 20px;
                padding: 10px;
              }
            }
          }

          /*------------------*/
          // contact
          /*------------------*/
.office, .sidepanel{
  margin-top: 50px;
}
          /*------------------*/
          // sidepanel
          /*------------------*/
.sidepanel{

  padding: 0 10px 0 20px;

  .profile{

    padding: 10px;

    &:before{
      display: block;
      content: "";
      width: 70%;
      border-bottom: 3px solid #9f9f9f;
    }
    a{
      color: inherit;
    }
    h4{
      line-height: 120%;
    }
    h5{
      margin: 10px 0 0 0;
      color: $border-top-color;
    }
    p, span{
      font-weight: normal;
      color: black;
      margin-bottom: .5rem !important;
    }

    span{
      font-size: 80%;
      margin-bottom: 0;
    }
  }

  .office-location{
      border-top: 4px double #333;
      padding-top: 20px;
  }

}

        /*------------------*/
          // Media Queries
          /*------------------*/


          /* Small only */
          @media screen and (max-width: 39.9375em) {
            .submenu {
              display: inline-block;
              position: absolute;
              top: 0;
              left: 100%;
            }
            .intro-list{
              text-align: left;
              margin-left: 10%;
            }
            .description-right{
              padding: 0 10px 0 60px;
            }
            .section-reverse{
              h1{
                font-size: 36px;
              }
            }
          }

          /* Medium and up */
          @media screen and (min-width: 40em) {
              .intro-list{
                margin-left: 30%;
                text-align: left;
              }

            }

            .fa-facebook-official a{
              font-family: $body-font-family !important;
              margin-bottom: 50px !important;
            }



          /* Medium only */
          @media screen and (min-width: 40em) and (max-width: 63.9375em) {}

          /* Large and up */
          @media screen and (min-width: 64em) {}

          /* Large only */
          @media screen and (min-width: 64em) and (max-width: 74.9375em) {}
